export const START_STATE = "INTRO";

let countdownInterval, gameInterval, scoreInterval;

// state machine outline orginally composed by Mike Wyatt
export const TRANSITIONS = {
  [START_STATE]: {
    // Begins the countdown to start. Clears out points.
    START_GAME: function (getSession, setSession) {
      // console.info(START_STATE + " START_GAME")
      const nextState = "COUNTDOWN_TO_START";

      const action = () => {
        countdownInterval = setInterval(() => {
          const [nextState, action] = TRANSITIONS.COUNTDOWN_TO_START.COUNT(getSession, setSession);
          const session = getSession();

          action();

          if (session.state.stage !== nextState) {
            setSession({
              ...session,
              state: {
                ...session.state,
                stage: nextState,
              }
            })
          }
        }, 1000);
      };
      return [nextState, action];
    },
  },
  COUNTDOWN_TO_START: {
    // Decrements the counter?
    COUNT: function (getSession, setSession) {
      // console.info("COUNTDOWN_TO_START COUNT")
      const nextState = "COUNTDOWN_TO_START";

      const session = getSession();

      if (session.state.countdownTimer > 0) {
        const nextState = "COUNTDOWN_TO_START";
        const action = () => {
          const session = getSession();
       
          setSession({
            state: {
              countdownTimer: session.state.countdownTimer - 1
            }
          })
        };

        return [nextState, action];
      }

      return TRANSITIONS.COUNTDOWN_TO_START.BEGIN_GAME(getSession, setSession)
    },
    // When the count is 0 this should be triggered.
    BEGIN_GAME: function async(getSession, setSession) {
      // console.info("COUNTDOWN_TO_START BEGIN_GAME")
      const nextState = "COMPETITION";

      const action = async () => {
        const session = getSession();

        clearInterval(countdownInterval);

        const maxTeamJoinCount = Math.max(...session.teams.map(({ joinCount }) => Number(joinCount)), 1);

        const targetTapCount = Math.max(maxTeamJoinCount * session.state.targetTapsPerSecond * session.state.gameTimer, 100);

        setSession({
          state: {
            targetTapCount
          }
        })

        gameInterval = setInterval(() => {
          const [nextState, action] = TRANSITIONS.COMPETITION.COMPETE(getSession, setSession);
          const session = getSession();

          action();

          if (session.state.stage !== nextState) {
            setSession({
              state: {
                stage: nextState,
              }
            })
          }
        }, 1000);
      };

      return [nextState, action];
    },
  },
  COMPETITION: {
    // This could keep the game going and decrement the countdown.
    COMPETE: function (getSession, setSession) {
      // console.info("COMPETITION COMPETE")
      const session = getSession();
      if (session.state.gameTimer > 0) {
        const nextState = "COMPETITION";

        const action = () => {
          const session = getSession();

          setSession({
            state: {
              gameTimer: session.state.gameTimer - 1
            }
          })
        };
        return [nextState, action];
      }
      return TRANSITIONS.COMPETITION.COMPLETE_GAME(getSession, setSession)
    },
    // This transition should occur when a completion condition is met.
    COMPLETE_GAME: function (getSession, setSession) {
      // console.info("COMPETITION COMPLETE_GAME")
      const nextState = "POST_GAME";
      const action = () => {
        const session = getSession();

        clearInterval(gameInterval);
        clearInterval(scoreInterval);
      };

      return [nextState, action];
    },
  },
  POST_GAME: {
    // Takes us back to the intro
    RESTART: function (getSession, setSession) {
      // console.info("POST_GAME RESTART")
      const nextState = START_STATE;
      const action = () => {
        const session = getSession();
        setSession({
          state: {
            id: 'state',
            countdownTimer: 15,
            gameTimer: 30,
            targetTapsPerSecond: 5,
            targetTapCount: 150,
            tapCounts: '',
            joinCounts: '',
            scores: '',
            stage: START_STATE,
          },
          transition: {
            id: 'transition',
            name: 'START_GAME',
          },
          teams: [
            {
              id: 'blue',
              joinCount: 0,
              tapCount: 0,
              offerTapCount: 0,
              sessionTeamsId: 'blue',
            },
            {
              id: 'red',
              joinCount: 0,
              tapCount: 0,
              offerTapCount: 0,
              sessionTeamsId: 'red',
            },
            {
              id: 'tan',
              joinCount: 0,
              tapCount: 0,
              offerTapCount: 0,
              sessionTeamsId: 'tan',
            },
          ],
          scores: {
            id: 'scores',
            scores: '',
            failsafe: false,
          },
        });
      };

      return [nextState, action];
    },
  },
};

export const HOST_ACTIONS_BY_STATE = {
  [START_STATE]: {
    forward: "START_GAME",
    back: null,
  },
  COUNTDOWN_TO_START: {
    forward: "BEGIN_GAME",
    back: null,
  },
  COMPETITION: {
    forward: "COMPLETE_GAME",
    back: null,
  },
  POST_GAME: {
    // forward: "REMATCH",
    back: "RESTART",
  },
};
